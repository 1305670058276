<template>
  <div class="container">
    <el-row style="height: 90%;">
      <el-col :span="12" class="left-content">
        <div class="company-name">{{userInfoMes.enterpriseName}}</div>
        <div>
          <p style="font-size: 24px;text-indent: 20px;">维护建议：</p>
          <p style="font-size: 16px;text-indent: 32px;">建议您优先更新<span class="text-color-gold" v-for="(item, index) of scoreUnscramble" :key="item">{{index == scoreUnscramble.length-1 ? item : (item + '，')}}</span>部分相关内容，有助于提升行业水平。</p>
        </div>
        <div class="btn-content">
          <!--<span class="btn-content-span" @click="handleChangeType('2')">查看工程信易贷评分<i class="el-icon-arrow-right"></i></span>-->
          <span class="btn-content-span right-span" @click="handleChangeType('3')">查看国行信e融评分<i class="el-icon-arrow-right"></i></span>
        </div>
      </el-col>
      <el-col :span="12">
        <div style="height: 80%;border-radius: 5px;">
          <div style="font-size: 24px;color: #fff;text-align: left;background-color: rgba(0,0,0,0.2);height: 50px;line-height: 50px;padding: 0 20px;border-radius: 6px 6px 0 0;">行业平均水平</div>
          <div ref="chartone" element-loading-background="rgba(0, 0, 0, 0)" element-loading-text="数据加载中……" v-loading="loading" style="height: 100%;color: #fff;background-color: rgba(0,0,0,0.1);padding: 0 20px 20px 20px;border-radius: 0 0 6px 6px;"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { getAdviceByCode, getCompanyRankByCode } from '@/api/query.js'
export default {
  name: 'a-score',
  data () {
    return {
      loading: true,
      scoreUnscramble: ['资质证书', '项目业绩'],
      scoreArr: [0, 0, 0, 0, 0],
      userInfoMes: {}
    }
  },
  mounted () {
    const vm = this
    if (vm.userInfo) {
      vm.userInfoMes = JSON.parse(vm.userInfo)
      if (vm.userInfoMes.businessInformationStatus === '1') {
        vm.initScoreUnscramble()
        vm.initScoreArr()
      }
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
    userInfo: {
      handler (val, oldval) {
        if (val) {
          const vm = this
          if (this.userInfo) {
            this.userInfoMes = JSON.parse(this.userInfo)
            if (vm.userInfoMes.businessInformationStatus === '1') {
              vm.initScoreUnscramble()
              vm.initScoreArr()
            }
          }
        }
      }
    }
  },
  methods: {
    handleChangeType: function (e) {
      this.$emit('btnActive', e)
    },
    initScoreUnscramble () {
      const vm = this
      const data = {
        code: vm.userInfoMes.taxpayerNumber
      }
      getAdviceByCode(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          if (res.maintainPro) {
            const arr = res.maintainPro.split('、')
            vm.scoreUnscramble = arr
          } else {
            vm.scoreUnscramble = ['资质证书', '项目业绩']
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 图标分数
    initScoreArr () {
      const vm = this
      const data = {
        code: vm.userInfoMes.taxpayerNumber
      }
      getCompanyRankByCode(data).then(result => {
        if (result.code === 0) {
          const obj = result.data
          console.log(obj)
          const arr = []
          if (obj.administrativeSanctionRScore < 0) {
            arr.push(0)
          } else if (obj.administrativeSanctionRScore > 100) {
            arr.push(100)
          } else {
            arr.push(obj.administrativeSanctionRScore)
          }
          if (obj.judicialRiskRScore < 0) {
            arr.push(0)
          } else if (obj.judicialRiskRScore > 100) {
            arr.push(100)
          } else {
            arr.push(obj.judicialRiskRScore)
          }
          if (obj.operateRScore < 0) {
            arr.push(0)
          } else if (obj.operateRScore > 100) {
            arr.push(100)
          } else {
            arr.push(obj.operateRScore)
          }
          if (obj.honorRScore < 0) {
            arr.push(0)
          } else if (obj.honorRScore > 100) {
            arr.push(100)
          } else {
            arr.push(obj.honorRScore)
          }
          if (obj.qualificationsRScore < 0) {
            arr.push(0)
          } else if (obj.qualificationsRScore > 100) {
            arr.push(100)
          } else {
            arr.push(obj.qualificationsRScore)
          }
          vm.scoreArr = arr
          vm.initEchart()
        } else {
          vm.scoreArr = [0, 0, 0, 0, 0]
          vm.initEchart()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initEchart: function () {
      const vm = this
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            },
            formatter: function (params) {
              return params + '%'
            }
          }
        },
        grid: {
          left: '1%',
          right: '7%',
          top: '1%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          splitNumber: 2,
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 20
            },
            formatter: function (value) {
              return value + '%'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['历史行政处罚', '司法风险', '运营情况', '荣誉情况', '资质情况'],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          offset: 2,
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 16
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 14,
            itemStyle: {
              normal: {
                color: 'rgb(226, 198, 121)',
                barBorderRadius: [7]
              }
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(255,255,255,0.5)',
              borderRadius: 7
            },
            data: vm.scoreArr
          }
        ]
      }
      var myChart = echarts.init(this.$refs.chartone)
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.left-content {
  text-align: left;
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  flex-wrap: wrap;
  .company-name {
    font-size: 30px;
  }
  .text-color-gold {
    color: #E7C478;
  }
  .btn-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .btn-content-span {
      width: 230px;
      height: 50px;
      line-height: 50px;
      background-color: rgba(255,255,255,0.1);
      border-radius: 8px;
      display: block;
      text-align: center;
    }
    .btn-content-span:hover {
      background-color: #E7C478;
      color: #5D6FE9;
    }
    .right-span {
      margin-left: 20px;
    }
  }
}
</style>
